import './App.css';
import * as React from 'react';
import Timetrial from './Views/Timetrial.js'


function App() {

  return (
    <Timetrial />
  );
}

export default App;
