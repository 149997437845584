import '../App.css';
import * as React from 'react';
import { NavLink } from "react-router-dom";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, TelegramIcon, TwitterIcon, WhatsappIcon,} from "react-share";
import correct from "../Assets/Sounds/correct.mp3";
import incorrect from "../Assets/Sounds/incorrect.mp3";
import trophysound from "../Assets/Sounds/trophy.mp3";
import logo from "../Assets/Images/trivialmania.png";

export default function Timetrial() {

  //Language settings

  const [language] = React.useState(
    () => JSON.parse(window.localStorage.getItem('language')) ?? "English"
  )

  React.useEffect(() => {
    window.localStorage.setItem('language', JSON.stringify(language))
  }, [language])

  // color settings

  const [color] = React.useState(
    () => JSON.parse(window.localStorage.getItem('color')) ?? "#36454F"
  )

  React.useEffect(() => {
    window.localStorage.setItem('color', JSON.stringify(color))
  }, [color])

  //Audio constants

  const [audioPlay, setAudioplay] = React.useState(
    () => JSON.parse(window.localStorage.getItem('audioPlay') ?? true)
  )

  React.useEffect(() => {
    window.localStorage.setItem('audioPlay', JSON.stringify(audioPlay))
  }, [audioPlay])

  const correctplay = new Audio(correct)
  const incorrectplay = new Audio(incorrect)


  const [currentQuestion, setCurrentquestion] = React.useState()
  const [currentNumber, setCurrentnumber] = React.useState()

  const [trophy, setTrophy] = React.useState(null)

  const audioEmoji = audioPlay === true ? '🔊' : '🔈'

  const [emojiAnswer, setEmojianswer] = React.useState()

  //First data

  const [questions, setQuestions] = React.useState(
    () => JSON.parse(window.localStorage.getItem('questions'))
  )

  const [questionsEs, setQuestionsEs] = React.useState(
    () => JSON.parse(window.localStorage.getItem('questionsEs'))
  )

  const [geography, setGeography] = React.useState(
    () => JSON.parse(window.localStorage.getItem('geography'))
  )

  const [geographyEs, setGeographyEs] = React.useState(
    () => JSON.parse(window.localStorage.getItem('geographyEs'))
  )

  const [history, setHistory] = React.useState(
    () => JSON.parse(window.localStorage.getItem('history'))
  )

  const [historyEs, setHistoryEs] = React.useState(
    () => JSON.parse(window.localStorage.getItem('historyEs'))
  )

  const [film, setFilm] = React.useState(
    () => JSON.parse(window.localStorage.getItem('film'))
  )

  const [filmEs, setFilmEs] = React.useState(
    () => JSON.parse(window.localStorage.getItem('filmEs'))
  )

  const [art, setArt] = React.useState(
    () => JSON.parse(window.localStorage.getItem('art'))
  )

  const [artEs, setArtEs] = React.useState(
    () => JSON.parse(window.localStorage.getItem('artEs'))
  )

  const [science, setScience] = React.useState(
    () => JSON.parse(window.localStorage.getItem('science'))
  )

  const [scienceEs, setScienceEs] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scienceEs'))
  )

  //Data to be set depending on number button pressed

  const [pokemonData, setPokemondata] = React.useState(questions)

  const [showQuestion, setShowquestion] = React.useState('')

  const [mistakes, setMistakes] = React.useState([])

  //Trophies
  //Geography

  const [geographyGold, setGeographygold] = React.useState(
    () => JSON.parse(window.localStorage.getItem('geographyGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('geographyGold', JSON.stringify(geographyGold))
  }, [geographyGold])

  const [geographySilver, setGeographysilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('geographySilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('geographySilver', JSON.stringify(geographySilver))
  }, [geographySilver])

  const [geographyBronze, setGeographybronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('geographyBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('geographyBronze', JSON.stringify(geographyBronze))
  }, [geographyBronze])

  //History

  const [historyGold, setHistorygold] =  React.useState(
    () => JSON.parse(window.localStorage.getItem('historyGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('historyGold', JSON.stringify(historyGold))
  }, [historyGold])

  const [historySilver, setHistorysilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('historySilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('historySilver', JSON.stringify(historySilver))
  }, [historySilver])

  const [historyBronze, setHistorybronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('historyBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('historyBronze', JSON.stringify(historyBronze))
  }, [historyBronze])

  //Entertainment

  const [entertainmentGold, setEntertainmentgold] = React.useState(
    () => JSON.parse(window.localStorage.getItem('entertainmentGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('entertainmentGold', JSON.stringify(entertainmentGold))
  }, [entertainmentGold])

  const [entertainmentSilver, setEntertainmentsilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('entertainmentSilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('entertainmentSilver', JSON.stringify(entertainmentSilver))
  }, [entertainmentSilver])

  const [entertainmentBronze, setEntertainmentbronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('entertainmentBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('entertainmentBronze', JSON.stringify(entertainmentBronze))
  }, [entertainmentBronze])

  //Art and Literature

  const [artGold, setArtgold] = React.useState(
    () => JSON.parse(window.localStorage.getItem('artGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('artGold', JSON.stringify(artGold))
  }, [artGold])

  const [artSilver, setArtsilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('artSilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('artSilver', JSON.stringify(artSilver))
  }, [artSilver])

  const [artBronze, setArtbronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('artBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('artBronze', JSON.stringify(artBronze))
  }, [artBronze])

  //Science and Nature

  const [scienceGold, setSciencegold] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scienceGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scienceGold', JSON.stringify(scienceGold))
  }, [scienceGold])

  const [scienceSilver, setSciencesilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scienceSilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scienceSilver', JSON.stringify(scienceSilver))
  }, [scienceSilver])

  const [scienceBronze, setSciencebronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scienceBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scienceBronze', JSON.stringify(scienceBronze))
  }, [scienceBronze])

  //Score

  const [scoreGold, setScoregold] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scoreGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scoreGold', JSON.stringify(scoreGold))
  }, [scoreGold])

  const [scoreSilver, setScoresilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scoreSilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scoreSilver', JSON.stringify(scoreSilver))
  }, [scoreSilver])

  const [scoreBronze, setScorebronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scoreBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scoreBronze', JSON.stringify(scoreBronze))
  }, [scoreBronze])

  //NumberOne

  const [numberOne, setNumberone] = React.useState(
    () => JSON.parse(window.localStorage.getItem('numberOne')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('numberOne', JSON.stringify(numberOne))
  }, [numberOne])

  //Geography

  const geographyGoldTrophy = require('../Assets/Images/GeographyGold.png')
  const geographySilverTrophy = require('../Assets/Images/GeographySilver.png')
  const geographyBronzeTrophy = require('../Assets/Images/GeographyBronze.png')

  //History

  const historyGoldTrophy = require('../Assets/Images/HistoryGold.png')
  const historySilverTrophy = require('../Assets/Images/HistorySilver.png')
  const historyBronzeTrophy = require('../Assets/Images/HistoryBronze.png')

  //Entertainment

  const entertainmentGoldTrophy = require('../Assets/Images/EntertainmentGold.png')
  const entertainmentSilverTrophy = require('../Assets/Images/EntertainmentSilver.png')
  const entertainmentBronzeTrophy = require('../Assets/Images/EntertainmentBronze.png')

  //Art and Literature

  const artGoldTrophy = require('../Assets/Images/ArtGold.png')
  const artSilverTrophy = require('../Assets/Images/ArtSilver.png')
  const artBronzeTrophy = require('../Assets/Images/ArtBronze.png')

  //Science and Nature

  const scienceGoldTrophy = require('../Assets/Images/ScienceGold.png')
  const scienceSilverTrophy = require('../Assets/Images/ScienceSilver.png')
  const scienceBronzeTrophy = require('../Assets/Images/ScienceBronze.png')

  //Score

  const scoreGoldTrophy = require('../Assets/Images/ScoreGold.png')
  const scoreSilverTrophy = require('../Assets/Images/ScoreSilver.png')
  const scoreBronzeTrophy = require('../Assets/Images/ScoreBronze.png')

  //NumberOne Trophy

  const numberOneTrophy = require('../Assets/Images/NumberOne.png')

  //Quotes

  const [quotes, setQuotes] = React.useState(
    () => JSON.parse(window.localStorage.getItem('quotes')) ?? ["TrivialMania is fun."]
  )

  const [quotesEs, setQuotesEs] = React.useState(
    () => JSON.parse(window.localStorage.getItem('quotesEs')) ?? ["TrivialMania mola."]
  )

  //Keeping track of the answers

  const answersInit = ["rattata"]

  const [answers2, setAnswers2] = React.useState(
    () => JSON.parse(window.localStorage.getItem('answers2')) ?? answersInit
  )

  React.useEffect(() => {
    window.localStorage.setItem('answers2', JSON.stringify(answers2))
  }, [answers2])

  const [answers3, setAnswers3] = React.useState(
    () => JSON.parse(window.localStorage.getItem('answers3')) ?? answersInit
  )

  React.useEffect(() => {
    window.localStorage.setItem('answers3', JSON.stringify(answers3))
  }, [answers3])

  const [answers4, setAnswers4] = React.useState(
    () => JSON.parse(window.localStorage.getItem('answers4')) ?? answersInit
  )

  React.useEffect(() => {
    window.localStorage.setItem('answers4', JSON.stringify(answers4))
  }, [answers4])

  const [answers5, setAnswers5] = React.useState(
    () => JSON.parse(window.localStorage.getItem('answers5')) ?? answersInit
  )

  React.useEffect(() => {
    window.localStorage.setItem('answers5', JSON.stringify(answers5))
  }, [answers5])

  const [answers6, setAnswers6] = React.useState(
    () => JSON.parse(window.localStorage.getItem('answers6')) ?? answersInit
  )

  React.useEffect(() => {
    window.localStorage.setItem('answers6', JSON.stringify(answers6))
  }, [answers6])

  //fetch all the data

  React.useEffect(() => {
    if ((window.localStorage.getItem('questions') === null || window.localStorage.getItem('questions') === 'null') && language === 'English'){
      fetch('/.netlify/functions/app/api/questions', {
        headers: {
          'authorization': process.env.REACT_APP_AUTH
        }
      })
      .then((response) => response.json()
      .then((response) => {
        const all = response.slice(0, 560)
        const geo = response.slice(0, 225)
        const his = response.slice(225, 300)
        const movie = response.slice(300, 400)
        const lit = response.slice(400, 500)
        const sci = response.slice(500, 560)
        const quo = response.slice(560, 660)
        setQuestions(all)
        setPokemondata(all)
        setGeography(geo)
        setHistory(his)
        setFilm(movie)
        setArt(lit)
        setScience(sci)
        setQuotes(quo)
      })
      .catch((error) => console.log(error))
    )
    }
  }, [language])

  React.useEffect(() => {
    if ((window.localStorage.getItem('questionsEs') === 'null' || window.localStorage.getItem('questionsEs') === null) && language === 'Spanish'){
      fetch('/.netlify/functions/app/api/questionsEs', {
        headers: {
          'authorization': process.env.REACT_APP_AUTH
        }
      })
      .then((response) => response.json()
      .then((response) => {
        const all = response.slice(0, 560)
        const geo = response.slice(0, 225)
        const his = response.slice(225, 300)
        const movie = response.slice(300, 400)
        const lit = response.slice(400, 500)
        const sci = response.slice(500, 560)
        const quo = response.slice(560, 660)
        setQuestionsEs(all)
        setPokemondata(all)
        setGeographyEs(geo)
        setHistoryEs(his)
        setFilmEs(movie)
        setArtEs(lit)
        setScienceEs(sci)
        setQuotesEs(quo)
      })
      .catch((error) => console.log(error))
    )
    }
  }, [language])


  React.useEffect(() => {
    window.localStorage.setItem('questions', JSON.stringify(questions))
  }, [questions])

  React.useEffect(() => {
    window.localStorage.setItem('questionsEs', JSON.stringify(questionsEs))
  }, [questionsEs])

  React.useEffect(() => {
    window.localStorage.setItem('geography', JSON.stringify(geography))
  }, [geography])

  React.useEffect(() => {
    window.localStorage.setItem('geographyEs', JSON.stringify(geographyEs))
  }, [geographyEs])

  React.useEffect(() => {
    window.localStorage.setItem('history', JSON.stringify(history))
  }, [history])

  React.useEffect(() => {
    window.localStorage.setItem('historyEs', JSON.stringify(historyEs))
  }, [historyEs])

  React.useEffect(() => {
    window.localStorage.setItem('film', JSON.stringify(film))
  }, [film])

  React.useEffect(() => {
    window.localStorage.setItem('filmEs', JSON.stringify(filmEs))
  }, [filmEs])

  React.useEffect(() => {
    window.localStorage.setItem('art', JSON.stringify(art))
  }, [art])

  React.useEffect(() => {
    window.localStorage.setItem('artEs', JSON.stringify(artEs))
  }, [artEs])

  React.useEffect(() => {
    window.localStorage.setItem('science', JSON.stringify(science))
  }, [science])

  React.useEffect(() => {
    window.localStorage.setItem('scienceEs', JSON.stringify(scienceEs))
  }, [scienceEs])

  React.useEffect(() => {
    window.localStorage.setItem('quotes', JSON.stringify(quotes))
  }, [quotes])

  React.useEffect(() => {
    window.localStorage.setItem('quotesEs', JSON.stringify(quotesEs))
  }, [quotesEs])

  //pokemon constants//
  const [correctName, setCorrectname] = React.useState('Londres')
  const [pokemonA, setPokemonA] = React.useState('Londres')
  const [pokemonB, setPokemonB] = React.useState('Madrid')
  const [pokemonC, setPokemonC] = React.useState('Roma')
  const [pokemonD, setPokemonD] = React.useState('París')

  //button constants//
  const [buttonPlay, setButtonplay] = React.useState({backgroundColor: "blue", color: "white", cursor: "cursor"})
  const [buttonA, setbuttonA] = React.useState({backgroundColor: "blue", color: "blue", cursor: "default"})
  const [buttonB, setbuttonB] = React.useState({backgroundColor: "blue", color: "blue", cursor: "default"})
  const [buttonC, setbuttonC] = React.useState({backgroundColor: "blue", color: "blue", cursor: "default"})
  const [buttonD, setbuttonD] = React.useState({backgroundColor: "blue", color: "blue", cursor: "default"})
  const [navButton, setNavbutton] = React.useState(1)

  //language

  React.useEffect(() => {
    if(language === "English") {
      setPokemondata(questions)
    } else {
      setPokemondata(questionsEs)
    }
  }, [language, questions, questionsEs])

  // Timer constant

  const [timer, setTimer] = React.useState(-1)

  React.useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer])

  //counter constant and Anti-cheat counter
  const initialCount = {count: 0}
  const initialCheat = {count: 0}

  function reducer(counter, action) {
    switch (action.type) {
      case 'increment':
      return {count: counter.count + 1}
      case 'reset':
      return {count: counter.count = 0}
      default:
      throw new Error();
    }
  }

  function reducerCheat(cheatCounter, action) {
    switch (action.type) {
      case 'increment':
      return {count: cheatCounter.count + 1}
      case 'reset':
      return {count: cheatCounter.count = 0}
      default:
      throw new Error();
    }
  }

  const [counter, dispatch] = React.useReducer(reducer, initialCount)
  const [cheatCounter, dispatchCheat] = React.useReducer(reducerCheat, initialCheat)

  //set emoji numbers

  React.useEffect(() => {

    const emojiNumbers = ["0️⃣", "1️⃣", "2️⃣", "3️⃣", "4️⃣", "5️⃣", "6️⃣", "7️⃣", "8️⃣", "9️⃣"]

    if(counter.count.toString().length === 2) {
      const getNumber = counter.count
      const firstDigitString = String(getNumber).charAt(0)
      const secondDigitString = String(getNumber).charAt(1)
      const firstDigitNumber = Number(firstDigitString)
      const secondDigitNumber = Number(secondDigitString)
      const emojiFirstDigit = emojiNumbers[firstDigitNumber]
      const emojiSecondDigit = emojiNumbers[secondDigitNumber]
      const emojiDoubleDigits = emojiFirstDigit + emojiSecondDigit
      setEmojianswer(emojiDoubleDigits)
    } else {
      const getNumber = counter.count
      const emojiSingle = emojiNumbers[getNumber]
      setEmojianswer(emojiSingle)
    }
  }, [counter])

  // Trophy Check

  React.useEffect(() => {
    const trophysoundplay = new Audio(trophysound)
    if(counter.count > 5 && scoreBronze === false) {
      setScorebronze(true)
      setTrophy(scoreBronzeTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(counter.count > 11 && scoreSilver === false) {
      setScoresilver(true)
      setTrophy(scoreSilverTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(counter.count > 17 && scoreGold === false) {
      setScoregold(true)
      setTrophy(scoreGoldTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers2.length > 10 && geographyBronze === false) {
      setGeographybronze(true)
      setTrophy(geographyBronzeTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers2.length > 50 && geographySilver === false) {
      setGeographysilver(true)
      setTrophy(geographySilverTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers2.length > 150 && geographyGold === false) {
      setGeographygold(true)
      setTrophy(geographyGoldTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers3.length > 10 && historyBronze === false) {
      setHistorybronze(true)
      setTrophy(historyBronzeTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers3.length > 30 && historySilver === false) {
      setHistorysilver(true)
      setTrophy(historySilverTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers3.length > 70 && historyGold === false) {
      setHistorygold(true)
      setTrophy(historyGoldTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers4.length > 10 && entertainmentBronze === false) {
      setEntertainmentbronze(true)
      setTrophy(entertainmentBronzeTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers4.length > 40 && entertainmentSilver === false) {
      setEntertainmentsilver(true)
      setTrophy(entertainmentSilverTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers4.length > 90 && entertainmentGold === false) {
      setEntertainmentgold(true)
      setTrophy(entertainmentGoldTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers5.length > 10 && artBronze === false) {
      setArtbronze(true)
      setTrophy(artBronzeTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers5.length > 40 && artSilver === false) {
      setArtsilver(true)
      setTrophy(artSilverTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers5.length > 90 && artGold === false) {
      setArtgold(true)
      setTrophy(artGoldTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers6.length > 10 && scienceBronze === false) {
      setSciencebronze(true)
      setTrophy(scienceBronzeTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers6.length > 30 && scienceSilver === false) {
      setSciencesilver(true)
      setTrophy(scienceSilverTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(answers6.length > 50 && scienceGold === false) {
      setSciencegold(true)
      setTrophy(scienceGoldTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
    if(scoreBronze === true && scoreSilver === true && scoreGold === true && geographyBronze === true && geographySilver === true && geographyGold === true && historyBronze === true && historySilver === true && historyGold === true && entertainmentBronze === true && entertainmentSilver === true && entertainmentGold === true && artBronze === true && artSilver === true && artGold === true && scienceBronze === true && scienceSilver === true && scienceGold === true && numberOne === false) {
      setNumberone(true)
      setTrophy(numberOneTrophy)
      if(audioPlay === true){trophysoundplay.play()}
    }
  }, [answers2.length, answers3.length, answers4.length, answers5.length, answers6.length, artBronze, artBronzeTrophy, artGold, artGoldTrophy, artSilver, artSilverTrophy, counter.count, entertainmentBronze, entertainmentBronzeTrophy, entertainmentGold, entertainmentGoldTrophy, entertainmentSilver, entertainmentSilverTrophy, geographyBronze, geographyBronzeTrophy, geographyGold, geographyGoldTrophy, geographySilver, geographySilverTrophy, historyBronze, historyBronzeTrophy, historyGold, historyGoldTrophy, historySilver, historySilverTrophy, scienceBronze, scienceBronzeTrophy, scienceGold, scienceGoldTrophy, scienceSilver, scienceSilverTrophy, scoreBronze, scoreBronzeTrophy, scoreGold, scoreGoldTrophy, scoreSilver, scoreSilverTrophy, setTrophy, numberOneTrophy, numberOne, audioPlay])

  //Score switch

  const score = (e) => {
    if(correctName === e.target.value) {
      if(audioPlay === true){correctplay.play()}
      dispatch({type: 'increment'})
    } else {
      if(audioPlay === true){incorrectplay.play()}

      setMistakes(mistakes => [...mistakes, {question: showQuestion, truth: correctName, fake: e.target.value}])
      }
    }

  //disable constant//
  const [disable, setDisable] = React.useState(true)

  //pokemon data handler that sets the pokemon generation

  const pokemonDatahandler = (n) => {
      if (n === 1){
        if(language === "English") {
          setPokemondata(questions)
        } else {
          setPokemondata(questionsEs)
        }
          setPokemonA('London')
          setPokemonB('Madrid')
          setPokemonC('Rome')
          setPokemonD('Paris')
          setDisable(true)
          setButtonplay({backgroundColor: "blue", color: "white",})
          setbuttonA({backgroundColor: "blue", color: "blue", cursor: "default"})
          setbuttonB({backgroundColor: "blue", color: "blue", cursor: "default"})
          setbuttonC({backgroundColor: "blue", color: "blue", cursor: "default"})
          setbuttonD({backgroundColor: "blue", color: "blue", cursor: "default"})
      } else if(n === 2){
        if(language === "English") {
          setPokemondata(geography)
        } else {
          setPokemondata(geographyEs)
        }
        setPokemonA('London')
        setPokemonB('Madrid')
        setPokemonC('Rome')
        setPokemonD('Paris')
        setDisable(true)
        setButtonplay({backgroundColor: "blue", color: "white",})
        setbuttonA({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonB({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonC({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonD({backgroundColor: "blue", color: "blue", cursor: "default"})
      } else if(n === 3){
        if(language === "English") {
          setPokemondata(history)
        } else {
          setPokemondata(historyEs)
        }
        setPokemonA('London')
        setPokemonB('Madrid')
        setPokemonC('Rome')
        setPokemonD('Paris')
        setDisable(true)
        setButtonplay({backgroundColor: "blue", color: "white",})
        setbuttonA({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonB({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonC({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonD({backgroundColor: "blue", color: "blue", cursor: "default"})
      } else if(n === 4){
        if(language === "English") {
          setPokemondata(film)
        } else {
          setPokemondata(filmEs)
        }
        setPokemonA('London')
        setPokemonB('Madrid')
        setPokemonC('Rome')
        setPokemonD('Paris')
        setDisable(true)
        setButtonplay({backgroundColor: "blue", color: "white",})
        setbuttonA({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonB({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonC({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonD({backgroundColor: "blue", color: "blue", cursor: "default"})
      } else if(n === 5){
        if(language === "English") {
          setPokemondata(art)
        } else {
          setPokemondata(artEs)
        }
        setPokemonA('London')
        setPokemonB('Madrid')
        setPokemonC('Rome')
        setPokemonD('Paris')
        setDisable(true)
        setButtonplay({backgroundColor: "blue", color: "white",})
        setbuttonA({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonB({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonC({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonD({backgroundColor: "blue", color: "blue", cursor: "default"})
      } else if(n === 6){
        if(language === "English") {
          setPokemondata(science)
        } else {
          setPokemondata(scienceEs)
        }
        setPokemonA('London')
        setPokemonB('Madrid')
        setPokemonC('Rome')
        setPokemonD('Paris')
        setDisable(true)
        setButtonplay({backgroundColor: "blue", color: "white",})
        setbuttonA({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonB({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonC({backgroundColor: "blue", color: "blue", cursor: "default"})
        setbuttonD({backgroundColor: "blue", color: "blue", cursor: "default"})
      } else {
          setPokemondata(questions)
      }
  }

  //Check if you earnt a trophy

  const badges = (e) => {
    if(navButton === 1 && correctName === e.target.value) {
      if(currentNumber < 226 && answers2.includes(currentNumber) === false) {
        setAnswers2(answers2 => [...answers2, currentNumber])
      } else if(currentNumber > 225 && currentNumber < 301 && answers3.includes(currentNumber) === false) {
        setAnswers3(answers3 => [...answers3, currentNumber])
      } else if(currentNumber > 300 && currentNumber < 401 && answers4.includes(currentNumber) === false) {
        setAnswers4(answers4 => [...answers4, currentNumber])
      } else if(currentNumber > 400 && currentNumber < 501 && answers5.includes(currentNumber) === false) {
        setAnswers5(answers5 => [...answers5, currentNumber])
      } else if(currentNumber > 500 && currentNumber < 561 && answers6.includes(currentNumber) === false) {
        setAnswers6(answers6 => [...answers6, currentNumber])
      }
    }
    if(navButton === 2 && correctName === e.target.value && answers2.includes(currentNumber) === false) {
        setAnswers2(answers2 => [...answers2, currentNumber])
      } else if(navButton === 3 && correctName === e.target.value && answers3.includes(currentNumber) === false) {
        setAnswers3(answers3 => [...answers3, currentNumber])
      } else if(navButton === 4 && correctName === e.target.value && answers4.includes(currentNumber) === false) {
        setAnswers4(answers4 => [...answers4, currentNumber])
      } else if(navButton === 5 && correctName === e.target.value && answers5.includes(currentNumber) === false) {
        setAnswers5(answers5 => [...answers5, currentNumber])
      } else if(navButton === 6 && correctName === e.target.value && answers6.includes(currentNumber) === false) {
        setAnswers6(answers6 => [...answers6, currentNumber])
      }
  }

  //Setinterval for anti-cheating
  React.useEffect(() => {

    if(timer < 60 && (timer !== 0 || timer === -1)){
      const intervalId = setInterval(() => {
        dispatchCheat({type: 'reset'})
      }, 1000);
      return () => {
      clearInterval(intervalId);
    };
    }
}, [timer]);

//Remove the question you previously answered

  const removeOne = () => {
    if(pokemonData.length != null && pokemonData.length > 1){
      setPokemondata(pokemonData => [...pokemonData.slice(1)])
    } else {
      if(navButton === 1) {
        if(language === "English") {
          setPokemondata(questions)
        } else {
          setPokemondata(questionsEs)
        }
      } else if(navButton === 2) {
        if(language === "English") {
          setPokemondata(geography)
        } else {
          setPokemondata(geographyEs)
        }
        } else if(navButton === 3) {
          if(language === "English") {
            setPokemondata(history)
          } else {
            setPokemondata(historyEs)
          }
        } else if(navButton === 4) {
          if(language === "English") {
            setPokemondata(film)
          } else {
            setPokemondata(filmEs)
          }
        } else if(navButton === 5) {
          if(language === "English") {
            setPokemondata(art)
          } else {
            setPokemondata(artEs)
          }
        } else if(navButton === 6) {
          if(language === "English") {
            setPokemondata(science)
          } else {
            setPokemondata(scienceEs)
          }
        }
    }
  }

  //play initializer//
  const play = async () => {

    //remove sounds for browser
    correctplay.remove()
    incorrectplay.remove()

    //setting the timer
    if(timer === -1) {
      setTimer(60)
      dispatch({type: 'reset'})
    }

    //Anti-cheating counter
    dispatchCheat({type: 'increment'})
    if(cheatCounter.count === 2){
      if(language === "English"){
        alert("We do not allow cheating at TrivialMania.")
      } else {
        alert("No permitimos hacer trampas en TrivialMania.")
      }
      dispatch({type: 'reset'})
    }

    //set answer buttons to blue and color white
    setbuttonA({backgroundColor: "blue", color: "white", cursor: "pointer"})
    setbuttonB({backgroundColor: "blue", color: "white", cursor: "pointer"})
    setbuttonC({backgroundColor: "blue", color: "white", cursor: "pointer"})
    setbuttonD({backgroundColor: "blue", color: "white", cursor: "pointer"})

    //make answer buttons active//
    setDisable(false)

    try {
      //shuffle pokemons and slice to four options//
      const shuffled = await pokemonData.sort(() => 0.5 - Math.random())

      //retrieve correct pokemon name and number//
      const correctName = await shuffled[0].correct
      const correctQuestion = await shuffled[0].question
      const number = await shuffled[0].number

      const audioQuestion = require(`../Assets/Sounds/${language === 'English' ? 'English' : 'Spanish'}/${number}.m4a`)
      const playQuestion = new Audio(audioQuestion)

      if(audioPlay === true){playQuestion.play()}

      setCurrentquestion(playQuestion)
      setCurrentnumber(number)


      //shuffle four options//
      const slicedShuffled = await shuffled[0].answers.sort(() => 0.5 - Math.random())

      const pokemonA = await slicedShuffled[0]
      const pokemonB = await slicedShuffled[1]
      const pokemonC = await slicedShuffled[2]
      const pokemonD = await slicedShuffled[3]

      //set pokemon states//
      setCorrectname(correctName)
      setShowquestion(correctQuestion)
      setPokemonA(pokemonA)
      setPokemonB(pokemonB)
      setPokemonC(pokemonC)
      setPokemonD(pokemonD)

      //set button states//
      setbuttonA({backgroundColor: "blue"})
      setbuttonB({backgroundColor: "blue"})
      setbuttonC({backgroundColor: "blue"})
      setbuttonD({backgroundColor: "blue"})

      removeOne()

    } catch(error) {
      alert(error)
    }
  }

  const answer = () => {
    if (pokemonA === correctName) {
      currentQuestion.pause()
      currentQuestion.remove()
      setbuttonA({backgroundColor: "green", boxShadow: "0 5px darkgreen"})
      setbuttonB({backgroundColor: "red", boxShadow: "0 5px brown"})
      setbuttonC({backgroundColor: "red", boxShadow: "0 5px brown"})
      setbuttonD({backgroundColor: "red", boxShadow: "0 5px brown"})

      setTimeout(play, 200)
    } else if (pokemonB === correctName) {
      currentQuestion.pause()
      currentQuestion.remove()
      setbuttonA({backgroundColor: "red", boxShadow: "0 5px brown"})
      setbuttonB({backgroundColor: "green", boxShadow: "0 5px darkgreen"})
      setbuttonC({backgroundColor: "red", boxShadow: "0 5px brown"})
      setbuttonD({backgroundColor: "red", boxShadow: "0 5px brown"})

      setTimeout(play, 200)
    } else if (pokemonC === correctName) {
      currentQuestion.pause()
      currentQuestion.remove()
      setbuttonA({backgroundColor: "red", boxShadow: "0 5px brown"})
      setbuttonB({backgroundColor: "red", boxShadow: "0 5px brown"})
      setbuttonC({backgroundColor: "green", boxShadow: "0 5px darkgreen"})
      setbuttonD({backgroundColor: "red", boxShadow: "0 5px brown"})

      setTimeout(play, 200)
    } else {
      currentQuestion.pause()
      currentQuestion.remove()
      setbuttonA({backgroundColor: "red", boxShadow: "0 5px brown"})
      setbuttonB({backgroundColor: "red", boxShadow: "0 5px brown"})
      setbuttonC({backgroundColor: "red", boxShadow: "0 5px brown"})
      setbuttonD({backgroundColor: "green", boxShadow: "0 5px darkgreen"})

      setTimeout(play, 200)
    }
  }

  //Ads for Google

  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
    script.async = true

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  //Ads for adinplay

    const aiptag = window.aiptag || {};
    aiptag.cmd = aiptag.cmd || [];
    aiptag.cmd.display = aiptag.cmd.display || [];
    aiptag.cmd.player = aiptag.cmd.player || [];

  //adinPlay ads logic

    aiptag.cmd.player.push(function() {
      aiptag.adplayer = new window.aipPlayer({
        AD_WIDTH: 960,
        AD_HEIGHT: 540,
        AD_DISPLAY: 'fullscreen', //default, fullscreen, center, fill
        LOADING_TEXT: 'loading advertisement',
        PREROLL_ELEM: function(){return document.getElementById('preroll')},
        AIP_COMPLETE: function (evt)  {
          /*******************
           ***** WARNING *****
           *******************
           Please do not remove the PREROLL_ELEM
           from the page, it will be hidden automaticly.
           If you do want to remove it use the AIP_REMOVE callback.
           AD_WIDTH: 960,
          AD_HEIGHT: 540,
          */
          console.log("Preroll Ad Completed: " + evt);
        },
        AIP_REMOVE: function ()  {
          // Here it's safe to remove the PREROLL_ELEM from the page if you want. But it's not recommend.
        }
      });
    });

  const show_preroll = React.useCallback(
  () => {
    //check if the adslib is loaded correctly or blocked by adblockers etc.
    if (typeof aiptag.adplayer !== 'undefined') {
      aiptag.cmd.player.push(function() { aiptag.adplayer.startPreRoll(); });
    } else {
      //Adlib didnt load this could be due to an adblocker, timeout etc.
      //Please add your script here that starts the content, this usually is the same script as added in AIP_COMPLETE or AIP_REMOVE.
      console.log("Ad Could not be loaded, load your content here");
    }
  },
  [aiptag.adplayer, aiptag.cmd.player],
);


  //score shown when timer finishes

  const TimedScore = () => {
    if(timer === 0) {
      currentQuestion.pause()
      currentQuestion.remove()

      // Google Ads

      const adsbygoogle = window.adsbygoogle || [].push({});

      //Timedscore logic

      const sortedQuotes = quotes.sort(() => 0.5 - Math.random())
      const sortedQuotesEs = quotesEs.sort(() => 0.5 - Math.random())

      const adBreak = function(o) {adsbygoogle.push(o);}

      const incorrectAnswers = mistakes.map((mistakes) =>
    <li key={mistakes.question.toString()}>
      <p>{mistakes.question}</p>
      <p>{language === "English" ? "Your Answer: " : "Tú Respuesta: "}{mistakes.fake}</p>
      <p>{language === "English" ? "Correct Answer: " : "Respuesta Correcta: "}{mistakes.truth}</p>
    </li>
  );

      const tweetHashtag = ["TrivialMania", "Trivia"]
      const categories = navButton === 1 ? "Every category" : navButton === 2 ? "Geography" : navButton === 3 ? "History" : navButton === 4 ? "Entretenimiento" : navButton === 5 ? "Art & Literature" : navButton === 6 ? "Science & Nature" : navButton === 7 ? "Seventh Generation Pokemon" : "Eighth Generation Pokemon";
      const categoriesEs = navButton === 1 ? "Todas las categorías" : navButton === 2 ? "Geografía" : navButton === 3 ? "Historia" : navButton === 4 ? "Entretenimiento" : navButton === 5 ? "Arte y Literatura" : navButton === 6 ? "Ciencia y Naturaleza" : navButton === 7 ? "Pokémon de Séptima Generación" : "Pokémon de Octava Generación";

      return (
        <div className="pokedex" alt="score" style={{ position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "1", width: "100%", height: "100%", backgroundColor: color, textAlign: "center", color: "white" }}>
        <div alt="score container" style={{ overflowY: "scroll", margin: "2%", width: "95%", height: "95%" }}>

        <h1>{language === "English" ? "Statistics" : "Estadísticas"}</h1>

        <img src={logo} className="logo" alt="logo" />

        <p>{language === "English" ? categories : categoriesEs}</p>
        <h2>{language === "English" ? "Score: " : "Puntuación: "}{emojiAnswer} </h2>

        <div alt="share buttons">
        <p>{language === "English" ? "Share your score with your friends!" : "Comparte tu puntuación con tus amigos!"}</p>
        <FacebookShareButton url="https://www.trivialmania.com/howtoplay" hashtag="#TrivialMania" quote={language === "English" ? categories + "\n Score: " + counter.count + "\n How many questions can you guess?" : categoriesEs + "\n Puntuación: " + counter.count + "\n Cuántas preguntas puedes acertar?" }><FacebookIcon size={32} round={true} /></FacebookShareButton>
        <TelegramShareButton url="https://www.trivialmania.com/howtoplay" title={language === "English" ? categories + "\n Score: " + emojiAnswer + "\n How many questions can you guess?" : categoriesEs + "\n Puntuación: " + emojiAnswer + "\n Cuántas preguntas puedes acertar?" }><TelegramIcon size={32} round={true} /></TelegramShareButton>
        <WhatsappShareButton url="https://www.trivialmania.com/howtoplay" title={language === "English" ? categories + "\n Score: " + counter.count + "\n How many questions can you guess?" : categoriesEs + "\n Puntuación: " + counter.count + "\n Cuántas preguntas puedes acertar?" }><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
        <TwitterShareButton url="https://www.trivialmania.com/howtoplay" hashtags={tweetHashtag} title={language === "English" ? categories + "\n Score: " + emojiAnswer + "\n How many questions can you guess?" : categoriesEs + "\n Puntuación: " + emojiAnswer + "\n Cuántas preguntas puedes acertar?" }><TwitterIcon size={32} round={true} /></TwitterShareButton>
        </div>

        <h3>{language === "English" ? "Incorrect Answers" : "Respuestas Incorrectas"}</h3>
        <ol>{incorrectAnswers}</ol>

        <p>
        {language === "English" ? sortedQuotes[0].quote : sortedQuotesEs[0].quote}
        </p>

        <p><button className="button" style={{ width: "50%"}} onClick={() => {
          adBreak({
            type: 'start',
            name: 'restart-game'
          });
          show_preroll();
          setTimer(-1);
          setDisable(true);
          setbuttonA({backgroundColor: "blue", color: "blue", cursor: "default"});
          setbuttonB({backgroundColor: "blue", color: "blue", cursor: "default"});
          setbuttonC({backgroundColor: "blue", color: "blue", cursor: "default"});
          setbuttonD({backgroundColor: "blue", color: "blue", cursor: "default"});
          setPokemonA("");
          setPokemonB("");
          setPokemonC("");
          setPokemonD("");
          setMistakes([]);
        }}>{language === "English" ? "Close" : "Cerrar"}</button></p>

         </div>
         </div>
    )
    } else {
      return (<></>)
    }
  }

  return (
    <div className="App" style={{ backgroundColor: color }}>

    <TimedScore />
    <div key={window.aipPlayer} id="preroll"></div>

    <div className="game" style={{ backgroundColor: color }}>

      <div className="question" alt="Question" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", color: "lightgreen" }}>
        <div>
          <img alt="Trophy" src={trophy} style={{ filter: trophy === null ? "brightness(0)" : "none" }} />
        </div>
        <p style={{ margin: "5px", fontSize: "24px" }}>
          {showQuestion}
        </p>
      </div>

      <div className="pokedex" alt="Remote" style={{ backgroundColor: color }}>

        <nav className="pokedexTop" alt="Navigation">
          <button className="pokedex-button users" alt="Sound" style={{backgroundColor: audioPlay === true ? "green" : "red", textDecoration: "none", height: "40px", width: "40px", fontSize: "20px", cursor: "pointer", borderStyle: "none"}} onClick={() => {audioPlay === true ? setAudioplay(false) : setAudioplay(true)}}>{audioEmoji}</button>
          <NavLink to="/settings" className="pokedex-button users" alt="Settings" style={{backgroundColor: "grey", textDecoration: "none", padding: "7px", fontSize: "20px"}}>⚙️</NavLink>
          <NavLink to="/trophy" className="pokedex-button users" alt="Trophies" style={{backgroundColor: "grey", textDecoration: "none", padding: "7px", fontSize: "20px"}}>🏆</NavLink>
          <NavLink to="/howtoplay" className="pokedex-button users" alt="How to Play" style={{backgroundColor: "grey", textDecoration: "none", padding: "7px", fontSize: "20px"}}>❓</NavLink>
        </nav>

        <div className="score" alt="score">
          <div alt="timer">{ language === "English" ? "Timer: " : "Tiempo: " }<label style={{ color: timer > 40 ? "lightgreen" : timer > 20 ? "yellow" : "red"}}>{timer === -1 ? 0 : timer}</label> </div>
          <div alt="Score">{ language === "English" ? "Score: " : "Puntuación: " }{counter.count}</div>
        </div>

        <div alt="play">
          <button className="button" alt="Play" style={buttonPlay} onClick={() => {play();}}>{language === "English" ? "Play" : "Jugar"}</button>
        </div>

        <div alt="answers">

          <button className="button-answer" id="answerA" alt="Answer A" disabled={disable} value={pokemonA} style={buttonA} onClick={(e) => {score(e); setDisable(true); answer(); badges(e); setTrophy(null);}}>{pokemonA}</button>
          <button className="button-answer" id="answerB" alt="Answer B" disabled={disable} value={pokemonB} style={buttonB} onClick={(e) => {score(e); setDisable(true); answer(); badges(e); setTrophy(null);}}>{pokemonB}</button>
          <button className="button-answer" id="answerC" alt="Answer C" disabled={disable} value={pokemonC} style={buttonC} onClick={(e) => {score(e); setDisable(true); answer(); badges(e); setTrophy(null);}}>{pokemonC}</button>
          <button className="button-answer" id="answerD" alt="Answer D" disabled={disable} value={pokemonD} style={buttonD} onClick={(e) => {score(e); setDisable(true); answer(); badges(e); setTrophy(null);}}>{pokemonD}</button>

        </div>

        <div alt="categories">
          <button className="button buttonNav" id="allCategories" alt="All Categories" style={{background: navButton === 1 ? "linear-gradient(to bottom, blue, yellow, pink, brown, green)" : `linear-gradient(to right, blue, yellow, pink, brown, green)`}} onClick={(n) => {pokemonDatahandler(n = 1); setNavbutton(1);}}>🌍</button>
          <button className="button buttonNav" id="geography" alt="Geography" style={{backgroundColor: navButton === 2 ? "cyan" : "blue"}} onClick={(n) => {pokemonDatahandler(n = 2); setNavbutton(2);}}>🗺️</button>
          <button className="button buttonNav" id="history" alt="History" style={{backgroundColor: navButton === 3 ? "yellow" : "orange"}} onClick={(n) => {pokemonDatahandler(n = 3); setNavbutton(3);}}>📜</button>
          <button className="button buttonNav" id="entertainment" alt="Entertainment" style={{backgroundColor: navButton === 4 ? "pink" : "purple"}} onClick={(n) => {pokemonDatahandler(n = 4); setNavbutton(4);}}>🎬</button>
          <button className="button buttonNav" id="art" alt="Art and Literature" style={{backgroundColor: navButton === 5 ? "red" : "brown"}} onClick={(n) => {pokemonDatahandler(n = 5); setNavbutton(5);}}>🎨</button>
          <button className="button buttonNav" id="science" alt="Science and Nature" style={{backgroundColor: navButton === 6 ? "lightgreen" : "green"}} onClick={(n) => {pokemonDatahandler(n = 6); setNavbutton(6);}}>🧪</button>
        </div>

      </div>

    </div>
    </div>
  );
}
