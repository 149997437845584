import '../App.css';
import * as React from 'react';
import { NavLink } from "react-router-dom";

export default function Privacy() {

  //Language settings

  const [language] = React.useState(
    () => JSON.parse(window.localStorage.getItem('language')) ?? "English"
  )

  // color settings

  const [color] = React.useState(
    () => JSON.parse(window.localStorage.getItem('color')) ?? "#36454F"
  )


  return (
    <div className="App" style={{ backgroundColor: color }}>

    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: color, color: "white"}}>
    <div className="pokedexForm" alt="About" style={{ overflowY: "scroll", margin: "5%", width: "95%", height: "95%", backgroundColor: color }}>

    <h1>{language === "English" ? "Privacy Policy for TrivialMania" : "Política de Privacidad de TrivialMania"}</h1>

<p>{language === "English" ? "At TrivialMania, accessible from https://www.trivialmania.com/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected by TrivialMania and how we use it." : "En TrivialMania, accesible desde https://www.trivialmania.com/, una de nuestras principales prioridades es la privacidad de nuestros visitantes. Este documento de Política de privacidad contiene tipos de información que TrivialMania recopila y cómo la usamos."}</p>

<p>{language === "English" ? `This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in TrivialMania. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the ` : `Esta Política de privacidad se aplica solo a nuestras actividades en línea y es válida para los visitantes de nuestro sitio web con respecto a la información que compartieron y/o recopilaron en TrivialMania. Esta política no se aplica a ninguna información recopilada fuera de línea o a través de canales que no sean este sitio web. Nuestra Política de privacidad se creó con la ayuda del `}<a style={{ color: "white" }} href="https://www.privacypolicygenerator.info">{ language === "English" ? "Free Privacy Policy Generator" : "Generador gratuito de políticas de privacidad" }</a></p>

<h2>{language === "English" ? "Consent" : "Consentimiento"}</h2>

<p>{language === "English" ? "By using our website, you hereby consent to our Privacy Policy and agree to its terms." : "Al utilizar nuestro sitio web, usted acepta nuestra Política de privacidad y acepta sus términos."}</p>

<h2>{language === "English" ? "Information we collect" : "Información que recolectamos"}</h2>

<p>{language === "English" ? "TrivialMania does not collect any personal information and only uses cookies and local storage so that the game works correctly. These are easily removable in your browser settings." : "TrivialMania no recopila ninguna información personal y solo utiliza cookies y almacenamiento local para que el juego funcione correctamente. Estos son fácilmente eliminables en la configuración de su navegador."}</p>


<h2>{language === "English" ? "How we use your information" : "Cómo usamos tú información"}</h2>

<p>{language === "English" ? "We use the information we collect in various ways, including to:" : "We use the information we collect in various ways, including to:"}</p>

<ul>
<li>{language === "English" ? "Provide, operate, and maintain our website" : "Proporcionar, operar y mantener nuestro sitio web"}</li>
<li>{language === "English" ? "Improve, personalize, and expand our website" : "Mejorar, personalizar y expandir nuestro sitio web"}</li>
<li>{language === "English" ? "Understand and analyze how you use our website" : "Comprender y analizar cómo utiliza nuestro sitio web"}</li>
<li>{language === "English" ? "Develop new products, services, features, and functionality" : "Desarrollar nuevos productos, servicios, características y funcionalidades."}</li>
<li>{language === "English" ? "Communicate with you, through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes" : "Comunicarnos con usted, a través de uno de nuestros socios, incluido el servicio de atención al cliente, para brindarle actualizaciones y otra información relacionada con el sitio web, y con fines promocionales y de marketing."}</li>
</ul>

<h2>{language === "English" ? "Log Files" : "Archivos de Registro"}</h2>

<p>{language === "English" ? "TrivialMania follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information." : "TrivialMania sigue un procedimiento estándar de uso de archivos de registro. Estos archivos registran a los visitantes cuando visitan sitios web. Todas las empresas de alojamiento hacen esto y forman parte del análisis de los servicios de alojamiento. La información recopilada por los archivos de registro incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), marca de fecha y hora, páginas de referencia/salida y posiblemente la cantidad de clics. Estos no están vinculados a ninguna información que sea personalmente identificable. El propósito de la información es analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y recopilar información demográfica."}</p>

<h2>{language === "English" ? "Cookies and Web Beacons" : "Cookies"}</h2>

<p>{language === "English" ? "Like any other website, TrivialMania uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information." : "Como cualquier otro sitio web, TrivialMania utiliza 'cookies'. Estas cookies se utilizan para almacenar información, incluidas las preferencias de los visitantes y las páginas del sitio web a las que accedió o visitó el visitante. La información se utiliza para optimizar la experiencia de los usuarios al personalizar el contenido de nuestra página web según el tipo de navegador de los visitantes y/u otra información."}</p>

<p>{language === "English" ? `For more general information on cookies, please read ` : `Para obtener más información general sobre las cookies, lea `}<a style={{ color: "white" }} href="https://www.generateprivacypolicy.com/#cookies">{language === "English" ? "the Cookies article on Generate Privacy Policy website" : "el artículo sobre Cookies en el sitio web Generate Privacy Policy"}< /a></p>

<h2>{language === "English" ? "Google DoubleClick DART Cookie" : "Google Cookie DobleClic DART"}</h2>

<p>{language === "English" ? `Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – ` : `Google es uno de los proveedores externos de nuestro sitio. También utiliza cookies, conocidas como cookies de DART, para mostrar anuncios a los visitantes de nuestro sitio en función de su visita a www.website.com y otros sitios en Internet. Sin embargo, los visitantes pueden optar por rechazar el uso de cookies de DART visitando la Política de privacidad de la red de anuncios y contenido de Google en la siguiente URL: `}<a style={{ color: "white" }} href="https://policies .google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>

<h2>{language === "English" ? "Our Advertising Partners" : "Nuestros Socios Publicitarios"}</h2>

<p>{language === "English" ? "Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below." : "Algunos de los anunciantes en nuestro sitio pueden usar cookies y balizas web. Nuestros socios publicitarios se enumeran a continuación. Cada uno de nuestros socios publicitarios tiene su propia Política de privacidad para sus políticas sobre datos de usuario. Para un acceso más fácil, tenemos un hipervínculo a sus Políticas de privacidad a continuación."}</p>


<p>{language === "English" ? "Google" : "Google"}</p>
<p><a style={{ color: "white" }} href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>


<h2>{language === "English" ? "Advertising Partners Privacy Policies" : "Políticas de Privacidad de Socios Publicitarios"}</h2>

<p>{language === "English" ? "You may consult this list to find the Privacy Policy for each of the advertising partners of TrivialMania." : "Puede consultar esta lista para encontrar la Política de privacidad de cada uno de los socios publicitarios de TrivialMania."}</p>

<p>{language === "English" ? "Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on TrivialMania, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit." : "Los servidores de anuncios o redes publicitarias de terceros utilizan tecnologías como cookies, JavaScript o balizas web que se utilizan en sus respectivos anuncios y enlaces que aparecen en TrivialMania, que se envían directamente al navegador de los usuarios. Reciben automáticamente su dirección IP cuando esto ocurre. Estas tecnologías se utilizan para medir la efectividad de sus campañas publicitarias y/o para personalizar el contenido publicitario que ve en los sitios web que visita."}</p>

<p>{language === "English" ? "Note that TrivialMania has no access to or control over these cookies that are used by third-party advertisers." : "Tenga en cuenta que TrivialMania no tiene acceso ni control sobre estas cookies que utilizan los anunciantes de terceros."}</p>

<h2>{language === "English" ? "Third Party Privacy Policies" : "Políticas de Privacidad de Terceros"}</h2>

<p>{language === "English" ? "TrivialMania's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options." : "La Política de privacidad de TrivialMania no se aplica a otros anunciantes o sitios web. Por lo tanto, le recomendamos que consulte las Políticas de privacidad respectivas de estos servidores de anuncios de terceros para obtener información más detallada. Puede incluir sus prácticas e instrucciones sobre cómo excluirse de ciertas opciones."}</p>

<p>{language === "English" ? "You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites." : "Puede optar por deshabilitar las cookies a través de las opciones de su navegador individual. Para conocer información más detallada sobre la gestión de cookies con navegadores web específicos, se puede encontrar en los sitios web respectivos de los navegadores."}</p>

<h2>{language === "English" ? "CCPA Privacy Rights (Do Not Sell My Personal Information)" : "Derechos de Privacidad de CCPA (No Vender mi Información Personal)"}</h2>

<p>{language === "English" ? "Under the CCPA, among other rights, California consumers have the right to:" : "Según la CCPA, entre otros derechos, los consumidores de California tienen derecho a:"}</p>
<p>{language === "English" ? "Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers." : "Solicitar que una empresa que recopila datos personales de un consumidor divulgue las categorías y piezas específicas de datos personales que una empresa ha recopilado sobre los consumidores."}</p>
<p>{language === "English" ? "Request that a business delete any personal data about the consumer that a business has collected." : "Solicitar que una empresa elimine cualquier dato personal sobre el consumidor que haya recopilado una empresa."}</p>
<p>{language === "English" ? "Request that a business that sells a consumer's personal data, not sell the consumer's personal data." : "Solicitar que una empresa que vende los datos personales de un consumidor, no venda los datos personales del consumidor."}</p>

<h2>{language === "English" ? "GDPR Data Protection Rights" : "Derechos de Protección de datos GDPR"}</h2>

<p>{language === "English" ? "We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:" : "Nos gustaría asegurarnos de que conoce todos sus derechos de protección de datos. Todo usuario tiene derecho a lo siguiente:"}</p>
<p>{language === "English" ? "The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service." : "El derecho de acceso: tiene derecho a solicitar copias de sus datos personales. Es posible que le cobremos una pequeña tarifa por este servicio."}</p>
<p>{language === "English" ? "The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete." : "El derecho a la rectificación: tiene derecho a solicitar que corrijamos cualquier información que crea que es inexacta. También tiene derecho a solicitar que completemos la información que cree que está incompleta."}</p>
<p>{language === "English" ? "The right to erasure – You have the right to request that we erase your personal data, under certain conditions." : "El derecho a borrar: tiene derecho a solicitar que eliminemos sus datos personales, bajo ciertas condiciones."}</p>
<p>{language === "English" ? "The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions." : "El derecho a restringir el procesamiento: tiene derecho a solicitar que restrinjamos el procesamiento de sus datos personales, bajo ciertas condiciones."}</p>
<p>{language === "English" ? "The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions." : "El derecho a oponerse al procesamiento: tiene derecho a oponerse a que procesemos sus datos personales, bajo ciertas condiciones."}</p>
<p>{language === "English" ? "The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions." : "El derecho a la portabilidad de datos: tiene derecho a solicitar que transfiramos los datos que hemos recopilado a otra organización, o directamente a usted, bajo ciertas condiciones."}</p>

<h2>{language === "English" ? "Children's Information" : "Información sobre Niños"}</h2>

<p>{language === "English" ? "Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity." : "Otra parte de nuestra prioridad es agregar protección para los niños mientras usan Internet. Alentamos a los padres y tutores a observar, participar y/o monitorear y guiar su actividad en línea."}</p>

<p>{language === "English" ? "TrivialMania does not knowingly collect any Personal Identifiable Information from children under the age of 13." : "TrivialMania no recopila a sabiendas ninguna Información de identificación personal de niños menores de 13 años."}</p>

      <nav alt="links">

      <NavLink to="/" className="button" style={({ isActive }) => {
        return {backgroundColor: isActive ? "cyan" : ""};}} alt="Home">🏠</NavLink>

      </nav>

    </div>
    </div>

    </div>
  );
}
