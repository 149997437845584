import '../App.css';
import * as React from 'react';
import { NavLink } from "react-router-dom";
import Select from 'react-select'
import logo from "../Assets/Images/trivialmania.png"

export default function Howtoplay() {

  //Language settings

  const [language, setLanguage] = React.useState(
    () => JSON.parse(window.localStorage.getItem('language')) ?? "English"
  )

  React.useEffect(() => {
    window.localStorage.setItem('language', JSON.stringify(language))
  }, [language])


  //Audio constants

  const [audioPlay, setAudioplay] = React.useState(
    () => JSON.parse(window.localStorage.getItem('audioPlay') ?? true)
  )

  React.useEffect(() => {
    window.localStorage.setItem('audioPlay', JSON.stringify(audioPlay))
  }, [audioPlay])

  const audioEmoji = audioPlay === true ? '🔊' : '🔈'

  // color settings

  const [color, setColor] = React.useState(
    () => JSON.parse(window.localStorage.getItem('color')) ?? "#36454F"
  )

  React.useEffect(() => {
    window.localStorage.setItem('color', JSON.stringify(color))
  }, [color])

  const options = [
  { value: '#36454F', label: `${language === "English" ? "Black" : "Negro"}` },
  { value: '#630330', label: `${language === "English" ? "Red" : "Rojo"}` },
  { value: '#FF7F50', label: `${language === "English" ? "Coral" : "Coral"}` },
  { value: '#FF69B4', label: `${language === "English" ? "Pink" : "Rosa"}` },
  { value: '#3EB489', label: `${language === "English" ? "Green" : "Verde"}` },
  { value: '#6699CC', label: `${language === "English" ? "Blue" : "Azul"}` },
  { value: '#280137', label: `${language === "English" ? "Purple" : "Púrpura"}` },
  { value: '#FFBF00', label: `${language === "English" ? "Yellow" : "Amarillo"}` }
]

const customStyles = {
option: (provided, state) => ({
  ...provided,
  borderBottom: '1px dotted pink',
  color: state.isSelected ? 'red' : 'blue',
  padding: 20,
}),
singleValue: (provided, state) => {
  const opacity = state.isDisabled ? 0.5 : 1;
  const transition = 'opacity 300ms';

  return { ...provided, opacity, transition };
}
}

  return (
    <div className="App" style={{ backgroundColor: color }}>

    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: color, color: "white"}}>

    <div alt="scroll container" style={{ overflowY: "scroll", margin: "2%" }}>
    <div alt="welcome container" style={{ margin: "2%" }}>
    <img src={logo} className="logo" alt="logo" style={{ width: "50%", height: "50%" }} />
    <h1>{ language === "English" ? "Welcome!" : "Bienvenidos!" }</h1>
    <p>{language === "English" ? "Here you will find a trivia game with five different categories! These include Geography, History, Entertainment, Art & Literature, and Science & Nature! Once you choose your category and press 'Play' you will have 60 seconds to answer as many categories as possible! You will also find that by answering questions you earn trophies! Good luck!" : "Un juego de preguntas con cinco categorías distintas! Encontrarás preguntas sobre Geografía, Historia, Entretenimiento, Arte y Literatura, y Ciencias y Naturaleza! En cuanto elijas la categoría que quieras jugar dale a 'Jugar' y tendrás 60 segundos para contestar a las preguntas que puedas! También encontrarás que al responder a preguntas irás ganando trofeos! Buena suerte!"}</p>
    <p><button className="pokedex-button" alt="pokedex-button" style={{ backgroundColor: "white", cursor: "pointer", borderStyle: "none" }} onClick={() => {language === "English" ? setLanguage("Spanish") : setLanguage("English")}}>{language === "English" ? "🇬🇧" : "🇪🇸"}</button>{language === "English" ? " Choose a language." : "Elige un idioma."}</p>
    <Select options={options} alt="choose colour" styles={customStyles} onChange={event => setColor(event.value)} placeholder={language === "English" ? "Choose a colour." : "Elige un color."} isSearchable={false} />

    <p>{language === "English" ? "Below are some instructions on how to use the page." : "A continuación puede encontrar las instrucciones para usar la página."}</p>

    <p><button className="pokedex-button" alt="Sound" style={{backgroundColor: audioPlay === true ? "green" : "red", textDecoration: "none", height: "40px", width: "40px", fontSize: "20px", cursor: "pointer", borderStyle: "none"}} onClick={() => {audioPlay === true ? setAudioplay(false) : setAudioplay(true)}}>{audioEmoji}</button>{language === "English" ? "This button allows you to turn the sound on or off." : "Este botón te permite apagar o encender el sonido."}</p>
    <p><button className="pokedex-button" alt="Settings" style={{backgroundColor: "grey", textDecoration: "none", padding: "7px", fontSize: "20px", borderStyle: "none"}}>⚙️</button>{language === "English" ? "This button will take you to the Settings page." : "Este botón te llevará a la página de ajustes."}</p>
    <p><button className="pokedex-button" alt="Trophies" style={{backgroundColor: "grey", textDecoration: "none", padding: "7px", fontSize: "20px", borderStyle: "none"}}>🏆</button>{language === "English" ? "This button will take you to the trophy page to see which trophies you have earnt." : "Este botón te llevará a la página de trofeos para ver cuales has conseguido."}</p>

    <p>{language === "English" ? "The following buttons allow you to choose a category to play." : "Los botones que puede encontrar a continuación te permiten elegir la categoría a la que quieres jugar."}</p>
    <p><button className="button buttonNav" alt="All Categories" style={{background: `linear-gradient(to right, blue, yellow, pink, brown, green)`}} >🌍</button>{language === "English" ? "All Categories" : "Todas las Categorías"}</p>
    <p><button className="button buttonNav"  alt="Geography" style={{backgroundColor: "blue"}}>🗺️</button>{language === "English" ? "Geography" : "Geografía"}</p>
    <p><button className="button buttonNav" alt="History" style={{backgroundColor: "orange"}}>📜</button>{language === "English" ? "History" : "Historia"}</p>
    <p><button className="button buttonNav" alt="Entertainment" style={{backgroundColor: "purple"}} >🎬</button>{language === "English" ? "Entertainment" : "Entretenimiento"}</p>
    <p><button className="button buttonNav" alt="Art and Literature" style={{backgroundColor: "brown"}}>🎨</button>{language === "English" ? "Art and Literature" : "Arte y Literatura"}</p>
    <p><button className="button buttonNav" alt="Science and Nature" style={{backgroundColor: "green"}} >🧪</button>{language === "English" ? "Science and Nature" : "Ciencia y Naturaleza"}</p>
    <div>
    <p>{language === "English" ? "Once you are ready. Press Play. You will have 60 seconds to answer the questions." : "Cuándo estés listo dale a Jugar. Tendrás 60 segundos para contestar a las preguntas."}</p>
    <p>{language === "English" ? "Press the 🏠 button to go to the game and start to play." : "Dale al botón 🏠 para que te lleve a la página principal y así empezar a jugar."}</p>
    <p>{language === "English" ? "Created by M. J. Pyott." : "Creado por M. J. Pyott."}</p>

    <nav>

    <NavLink to="/" className="button" style={({ isActive }) => {
      return {backgroundColor: isActive ? "cyan" : "", marginBottom: "25px"};}} alt="Home">🏠</NavLink>

    </nav>

    </div>

    </div>
    </div>

    </div>
    </div>
  );
}
