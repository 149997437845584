import '../App.css';
import * as React from 'react';
import { NavLink } from "react-router-dom";

export default function Error() {

  // color settings

  const [color] = React.useState(
    () => JSON.parse(window.localStorage.getItem('color')) ?? "purple"
  )

  React.useEffect(() => {
    window.localStorage.setItem('color', JSON.stringify(color))
  }, [color])

  return (
    <div className="App" style={{ backgroundColor: color }}>

    <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
    <div className="pokedexForm" alt="About" style={ { width: "90%", height: "90%", backgroundColor: color } }>

      <h1 style={ { color: "white", textAlign: "center" } }>Error!</h1>
      <p style={ { color: "white", textAlign: "center" } }>404 Page Not Found!</p>

      <div alt="links" style={ { display: "flex", justifyContent: "center", alignItems: "center" } }>

      <NavLink to="/" className="button" style={({ isActive }) => {
        return {backgroundColor: isActive ? "cyan" : ""};}} alt="FirstGeneration">Home</NavLink>

      </div>

    </div>
    </div>

    </div>
  );
}
