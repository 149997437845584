import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Settings from './Views/Settings.js'
import Howtoplay from './Views/Howtoplay.js'
import Profile from './Views/Profile.js'
import Trophy from './Views/Trophy.js'
import Privacy from './Views/Privacy.js'
import Error from './Views/Error.js'
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/howtoplay" element={<Howtoplay />} />
      <Route path="/trophy" element={<Profile />} />
      <Route path="/trophy/:category/:metal" element={<Trophy />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="*" element={<Error />} />
    </Routes>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
