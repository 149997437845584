import '../App.css';
import * as React from 'react';
import { NavLink } from "react-router-dom";

export default function Profile() {

  //Language settings

  const [language] = React.useState(
    () => JSON.parse(window.localStorage.getItem('language')) ?? "English"
  )

  React.useEffect(() => {
    window.localStorage.setItem('language', JSON.stringify(language))
  }, [language])

  // color settings

  const [color] = React.useState(
    () => JSON.parse(window.localStorage.getItem('color')) ?? "#36454F"
  )

  React.useEffect(() => {
    window.localStorage.setItem('color', JSON.stringify(color))
  }, [color])


  //Trophies
  //Geography

  const [geographyGold] = React.useState(
    () => JSON.parse(window.localStorage.getItem('geographyGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('geographyGold', JSON.stringify(geographyGold))
  }, [geographyGold])

  const [geographySilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('geographySilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('geographySilver', JSON.stringify(geographySilver))
  }, [geographySilver])

  const [geographyBronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('geographyBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('geographyBronze', JSON.stringify(geographyBronze))
  }, [geographyBronze])

  //History

  const [historyGold] =  React.useState(
    () => JSON.parse(window.localStorage.getItem('historyGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('historyGold', JSON.stringify(historyGold))
  }, [historyGold])

  const [historySilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('historySilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('historySilver', JSON.stringify(historySilver))
  }, [historySilver])

  const [historyBronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('historyBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('historyBronze', JSON.stringify(historyBronze))
  }, [historyBronze])

  //Entertainment

  const [entertainmentGold] = React.useState(
    () => JSON.parse(window.localStorage.getItem('entertainmentGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('entertainmentGold', JSON.stringify(entertainmentGold))
  }, [entertainmentGold])

  const [entertainmentSilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('entertainmentSilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('entertainmentSilver', JSON.stringify(entertainmentSilver))
  }, [entertainmentSilver])

  const [entertainmentBronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('entertainmentBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('entertainmentBronze', JSON.stringify(entertainmentBronze))
  }, [entertainmentBronze])

  //Art and Literature

  const [artGold] = React.useState(
    () => JSON.parse(window.localStorage.getItem('artGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('artGold', JSON.stringify(artGold))
  }, [artGold])

  const [artSilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('artSilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('artSilver', JSON.stringify(artSilver))
  }, [artSilver])

  const [artBronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('artBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('artBronze', JSON.stringify(artBronze))
  }, [artBronze])

  //Science and Nature

  const [scienceGold] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scienceGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scienceGold', JSON.stringify(scienceGold))
  }, [scienceGold])

  const [scienceSilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scienceSilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scienceSilver', JSON.stringify(scienceSilver))
  }, [scienceSilver])

  const [scienceBronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scienceBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scienceBronze', JSON.stringify(scienceBronze))
  }, [scienceBronze])

  //Score

  const [scoreGold] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scoreGold')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scoreGold', JSON.stringify(scoreGold))
  }, [scoreGold])

  const [scoreSilver] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scoreSilver')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scoreSilver', JSON.stringify(scoreSilver))
  }, [scoreSilver])

  const [scoreBronze] = React.useState(
    () => JSON.parse(window.localStorage.getItem('scoreBronze')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('scoreBronze', JSON.stringify(scoreBronze))
  }, [scoreBronze])

  //NumberOne

  const [numberOne] = React.useState(
    () => JSON.parse(window.localStorage.getItem('numberOne')) ?? false
  )

  React.useEffect(() => {
    window.localStorage.setItem('numberOne', JSON.stringify(numberOne))
  }, [numberOne])

  //Quotes

  const [quotes, setQuotes] = React.useState(
    () => JSON.parse(window.localStorage.getItem('quotes')) ?? []
  )

  const [quotesEs, setQuotesEs] = React.useState(
    () => JSON.parse(window.localStorage.getItem('quotesEs')) ?? []
  )

  React.useEffect(() => {
    if (window.localStorage.getItem('quotes') === null){
      fetch('/.netlify/functions/app/api/quotes', {
        headers: {
          'authorization': process.env.REACT_APP_AUTH
        }
      })
      .then((response) => response.json()
      .then((response) => {
        setQuotes(response)
      })
      .catch((error) => console.log(error))
    )
    }
  }, [])

  React.useEffect(() => {
    if (window.localStorage.getItem('quotesEs') === null){
      fetch('/.netlify/functions/app/api/quoteses', {
        headers: {
          'authorization': process.env.REACT_APP_AUTH
        }
      })
      .then((response) => response.json()
      .then((response) => {
        setQuotesEs(response)
      })
      .catch((error) => console.log(error))
    )
    }
  }, [])

  React.useEffect(() => {
    window.localStorage.setItem('quotes', JSON.stringify(quotes))
  }, [quotes])

  React.useEffect(() => {
    window.localStorage.setItem('quotesEs', JSON.stringify(quotesEs))
  }, [quotesEs])

  //Geography

  const geographyGoldTrophy = require('../Assets/Images/GeographyGold.png')
  const geographySilverTrophy = require('../Assets/Images/GeographySilver.png')
  const geographyBronzeTrophy = require('../Assets/Images/GeographyBronze.png')

  //History

  const historyGoldTrophy = require('../Assets/Images/HistoryGold.png')
  const historySilverTrophy = require('../Assets/Images/HistorySilver.png')
  const historyBronzeTrophy = require('../Assets/Images/HistoryBronze.png')

  //Entertainment

  const entertainmentGoldTrophy = require('../Assets/Images/EntertainmentGold.png')
  const entertainmentSilverTrophy = require('../Assets/Images/EntertainmentSilver.png')
  const entertainmentBronzeTrophy = require('../Assets/Images/EntertainmentBronze.png')

  //Art and Literature

  const artGoldTrophy = require('../Assets/Images/ArtGold.png')
  const artSilverTrophy = require('../Assets/Images/ArtSilver.png')
  const artBronzeTrophy = require('../Assets/Images/ArtBronze.png')

  //Science and Nature

  const scienceGoldTrophy = require('../Assets/Images/ScienceGold.png')
  const scienceSilverTrophy = require('../Assets/Images/ScienceSilver.png')
  const scienceBronzeTrophy = require('../Assets/Images/ScienceBronze.png')

  //Score

  const scoreGoldTrophy = require('../Assets/Images/ScoreGold.png')
  const scoreSilverTrophy = require('../Assets/Images/ScoreSilver.png')
  const scoreBronzeTrophy = require('../Assets/Images/ScoreBronze.png')

  //NumberOne Trophy

  const numberOneTrophy = require('../Assets/Images/NumberOne.png')


  return (
    <div className="App" style={{ backgroundColor: color }}>

    <div alt="profile" style={{ display: "flex", height: "100%", width: "100%", backgroundColor: color, justifyContent: "center", alignItems: "center" }}>

      <div>
      <h1 style={{color: "white", textAlign: "center"}}>{language === "English" ? "Trophies" : "Trofeos"}</h1>

      <label style={{ color: "white", textAlign: "center", margin: "10px" }}>{language === "English" ? "Geography" : "Geografía"}</label>
      <nav>
      <NavLink to="/trophy/geography/gold"><img className="badge" src={ geographyGoldTrophy } alt="Geography Gold Trophy" style={ { filter: geographyGold === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/geography/silver"><img className="badge" src={ geographySilverTrophy } alt="Geography Silver Trophy" style={ { filter: geographySilver === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/geography/bronze"><img className="badge" src={ geographyBronzeTrophy } alt="Geography Bronze Trophy" style={ { filter: geographyBronze === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      </nav>

      <label style={{ color: "white", textAlign: "center", margin: "10px" }}>{language === "English" ? "History" : "Historia"}</label>
      <nav>
      <NavLink to="/trophy/history/gold"><img className="badge" src={ historyGoldTrophy } alt="History Gold Trophy" style={ { filter: historyGold === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/history/silver"><img className="badge" src={ historySilverTrophy } alt="History Silver Trophy" style={ { filter: historySilver === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/history/bronze"><img className="badge" src={ historyBronzeTrophy } alt="History Bronze Trophy" style={ { filter: historyBronze === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      </nav>

      <label style={{ color: "white", textAlign: "center", margin: "10px" }}>{language === "English" ? "Entertainment" : "Entretenimiento"}</label>
      <div>
      <NavLink to="/trophy/entertainment/gold"><img className="badge" src={ entertainmentGoldTrophy } alt="Entertainment Gold Trophy" style={ { filter: entertainmentGold === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/entertainment/silver"><img className="badge" src={ entertainmentSilverTrophy } alt="Entertainment Silver Trophy" style={ { filter: entertainmentSilver === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/entertainment/bronze"><img className="badge" src={ entertainmentBronzeTrophy } alt="Entertainment Bronze Trophy" style={ { filter: entertainmentBronze === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      </div>

      <label style={{ color: "white", textAlign: "center", margin: "10px" }}>{language === "English" ? "Art & Literature" : "Arte y Literatura"}</label>
      <nav>
      <NavLink to="/trophy/art/gold"><img className="badge" src={ artGoldTrophy } alt="Art & Literature Gold Trophy" style={ { filter: artGold === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/art/silver"><img className="badge" src={ artSilverTrophy } alt="Art & Literature Silver Trophy" style={ { filter: artSilver === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/art/bronze"><img className="badge" src={ artBronzeTrophy } alt="Art & Literature Bronze Trophy" style={ { filter: artBronze === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      </nav>

      <label style={{ color: "white", textAlign: "center", margin: "10px" }}>{language === "English" ? "Science & Nature" : "Ciencia y Naturaleza"}</label>
      <nav>
      <NavLink to="/trophy/science/gold"><img className="badge" src={ scienceGoldTrophy } alt="Science & Nature Gold Trophy" style={ { filter: scienceGold === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/science/silver"><img className="badge" src={ scienceSilverTrophy } alt="Science & Nature Silver Trophy" style={ { filter: scienceSilver === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/science/bronze"><img className="badge" src={ scienceBronzeTrophy } alt="Science & Nature Bronze Trophy" style={ { filter: scienceBronze === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      </nav>

      <label style={{ color: "white", textAlign: "center", margin: "10px" }}>{language === "English" ? "Score" : "Puntuación"}</label>
      <nav>
      <NavLink to="/trophy/score/gold"><img className="badge" src={ scoreGoldTrophy } alt="Score Gold Trophy" style={ { filter: scoreGold === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/score/silver"><img className="badge" src={ scoreSilverTrophy } alt="Score Silver Trophy" style={ { filter: scoreSilver === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      <NavLink to="/trophy/score/bronze"><img className="badge" src={ scoreBronzeTrophy } alt="Score Bronze Trophy" style={ { filter: scoreBronze === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      </nav>

      <label style={{ color: "white", textAlign: "center", margin: "10px" }}>{language === "English" ? "Number One" : "Número Uno"}</label>
      <nav>
      <NavLink to="/trophy/number/one"><img className="badge" src={ numberOneTrophy } alt="Number One Trophy" style={ { filter: numberOne === false ? "brightness(0)" : "brightness()"} } /></NavLink>
      </nav>

      <nav alt="links" style={{ display: "flex", justifyContent: "center", margin: "20px" }}>

      <NavLink to="/" className="button" style={({ isActive }) => {
        return {backgroundColor: isActive ? "cyan" : "", marginBottom: "25px"};}} alt="FirstGeneration">🏠</NavLink>

      </nav>

      </div>

    </div>

    </div>
  );
}
