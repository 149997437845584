import '../App.css';
import * as React from 'react';
import { NavLink } from "react-router-dom";
import Select from 'react-select'

export default function Settings() {

  //Language settings

  const [language, setLanguage] = React.useState(
    () => JSON.parse(window.localStorage.getItem('language')) ?? "English"
  )

  React.useEffect(() => {
    window.localStorage.setItem('language', JSON.stringify(language))
  }, [language])

  // color settings

  const [color, setColor] = React.useState(
    () => JSON.parse(window.localStorage.getItem('color')) ?? "#36454F"
  )

  React.useEffect(() => {
    window.localStorage.setItem('color', JSON.stringify(color))
  }, [color])

  const options = [
  { value: '#36454F', label: `${language === "English" ? "Black" : "Negro"}` },
  { value: '#630330', label: `${language === "English" ? "Red" : "Rojo"}` },
  { value: '#FF7F50', label: `${language === "English" ? "Coral" : "Coral"}` },
  { value: '#FF69B4', label: `${language === "English" ? "Pink" : "Rosa"}` },
  { value: '#3EB489', label: `${language === "English" ? "Green" : "Verde"}` },
  { value: '#6699CC', label: `${language === "English" ? "Blue" : "Azul"}` },
  { value: '#280137', label: `${language === "English" ? "Purple" : "Púrpura"}` },
  { value: '#FFBF00', label: `${language === "English" ? "Yellow" : "Amarillo"}` }
]

const customStyles = {
option: (provided, state) => ({
  ...provided,
  borderBottom: '1px dotted pink',
  color: state.isSelected ? 'red' : 'blue',
  padding: 20,
}),
singleValue: (provided, state) => {
  const opacity = state.isDisabled ? 0.5 : 1;
  const transition = 'opacity 300ms';

  return { ...provided, opacity, transition };
}
}

  return (
    <div className="App" style={{ backgroundColor: color }}>

    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: color, color: "white"}}>
    <div className="pokedexForm" alt="About" style={{ width: "70%", height: "80%", backgroundColor: color }}>

    <p><button className="pokedex-button" alt="pokedex-button" style={{ backgroundColor: "white", cursor: "pointer", borderStyle: "none" }} onClick={() => {language === "English" ? setLanguage("Spanish") : setLanguage("English")}}>{language === "English" ? "🇬🇧" : "🇪🇸"}</button>{language === "English" ? " Choose a language." : "Elige un idioma."}</p>
    <hr></hr>
    <Select options={options} alt="choose colour" styles={customStyles} onChange={event => setColor(event.value)} placeholder={language === "English" ? "Choose a colour." : "Elige un color."} isSearchable={false} />
    <hr></hr>
    <p>{language === "English" ? "Created by M. J. Pyott." : "Creado por M. J. Pyott."}</p>

      <nav alt="links">

      <NavLink to="/privacy" className="button" style={({ isActive }) => {
        return {backgroundColor: isActive ? "cyan" : ""};}} alt="Privacy">{language === "English" ? "Privacy" : "Privacidad"}</NavLink>

      <NavLink to="/" className="button" style={({ isActive }) => {
        return {backgroundColor: isActive ? "cyan" : ""};}} alt="Home">🏠</NavLink>

      </nav>

    </div>
    </div>

    </div>
  );
}
